<template>
  <div class="subsales-otp-form row align-items-end">
    <fd-input
      v-model="offerToPurchase.purchasePriceNum"
      class="col-12 sm-col-6 md-col-3 px-1 mb-2"
      label="Purchase Price(Numbers)"
      name="purchasePriceNum"
      type="number"
      placeholder="2000"
      :validators="[...getIsFieldRequired()]"
    >
    </fd-input>
    <div class="col-12 px-1 mb-2">
      <fd-input
        v-model="offerToPurchase.propertyAddress"
        class=""
        label="Property Full Address"
        name="propertyAddress"
        type="text"
        :validators="[...getIsFieldRequired()]"
      ></fd-input>
      <fd-button
        v-if="autofillAddress"
        class="bordered main mt-1"
        @click="offerToPurchase.propertyAddress = autofillAddress() || ''"
      >
        Autofill From Property
      </fd-button>
    </div>
    <fd-input
      v-model="offerToPurchase.additionalInclude"
      class="col-12 px-1 mb-2"
      label="Additional Includes"
      name="additionalInclude"
      type="text"
      placeholder='eg: A sofa and a refridgerator ( "-" if none)'
    ></fd-input>

    <span class="col-12 lg-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.depositRate1"
          class="pr-1"
          label="First Deposit Rate (%)"
          name="depositRate1"
          type="number"
          placeholder="2"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="
            `The first deposit, pay after OTP sign, % of the Purchase Price.<br>
                          <i>First Deposit + Second Deposit cannot be more than 10%.</i>`
          "
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <span class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.depositAmount1"
          class="pr-1"
          label="First Deposit Amount"
          name="depositAmount1"
          type="number"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.depositAmount1"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <span class="col-12 lg-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.depositRate2"
          class="pr-1"
          label="Second Deposit Rate (%)"
          name="depositRate2"
          type="number"
          placeholder="8"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.depositRate2"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <span class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.depositAmount2"
          class="pr-1"
          label="Second Deposit Amount"
          name="depositAmount2"
          type="number"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.depositAmount2"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>

    <span class="col-12 px-1 mb-2">
      <div class="col-6 d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.balancePaidWithinDays1"
          class="pr-1"
          label="Balance Paid Within (day)"
          name="balancePaidWithinDays1"
          type="number"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 1)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.balancePaidWithinDays1"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>

      <div class="col-6 d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.balancePaidWithinDays2"
          class="pr-1"
          label="Vendor Balance Paid Within (day)"
          name="balancePaidWithinDays2"
          type="number"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 1)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.balancePaidWithinDays2"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>

    <span class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.gracePeriod"
          class="pr-1"
          label="Grace Period (day)"
          name="gracePeriod"
          type="number"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.gracePeriod"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <span class="col-12 lg-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.interestRate"
          class="pr-1"
          label="Interest Rate (%)"
          name="interestRate"
          type="number"
          :validators="[...getIsFieldRequired()]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.interestRate"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>

    <span class="col-12 lg-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.spaExecutionDays1"
          class="pr-1"
          label="SPA Execute Within (working day)"
          name="spaExecutionDays1"
          type="number"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 1)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.spaExecutionDays1"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <span class="col-12 lg-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.spaExecutionDays2"
          class="pr-1"
          label="Vendor SPA Execute Within (working day)"
          name="spaExecutionDays2"
          type="number"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 1)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.spaExecutionDays2"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>

    <fd-input
      v-model="offerToPurchase.specialCondition"
      class="col-12 px-1 mb-2"
      label="Special Condition"
      name="specialCondition"
      type="text"
    ></fd-input>

    <span class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.paymentAmount"
          class="col-10 pr-1"
          label="Payment Amount"
          name="paymentAmount"
          type="number"
          placeholder="200"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 2)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.paymentAmount"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
    <fd-input
      v-model="offerToPurchase.vendorName"
      class="col-12 sm-col-6 md-col-6 lg-col-6 xl-col-3 px-1 mb-2"
      label="Vendor's Name"
      name="vendorName"
      type="text"
    ></fd-input>
    <span class="col-12 sm-col-6 px-1 mb-2">
      <div class="d-flex align-items-end">
        <fd-input
          v-model="offerToPurchase.otpValidDays"
          class="pr-1"
          label="OTP Validity (working day)"
          name="otpValidDays"
          type="number"
          :validators="[...getIsFieldRequired(), (val) => minValue(val, 1)]"
        ></fd-input>
        <!-- hint button -->
        <button
          type="button"
          class="btn circle"
          v-tooltip="otpToolTip.otpValidDays"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </span>
  </div>
</template>

<script>
import {
  required,
  minValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    otpData: {
      type: Object
    },
    autofillAddress: {
      type: Function
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      offerToPurchase: {
        purchasePriceNum: "",
        propertyAddress: "",
        additionalInclude: "",
        depositRate1: "",
        depositAmount1: "",
        depositRate2: "",
        depositAmount2: "",
        balancePaidWithinDays1: "",
        balancePaidWithinDays2: "",
        gracePeriod: "",
        interestRate: "",
        spaExecutionDays1: "",
        spaExecutionDays2: "",
        specialCondition: "",
        paymentAmount: "",
        vendorName: "",
        otpValidDays: ""
      },
      otpToolTip: {
        depositRate1: `The first deposit, pay after OTP sign, % of the Purchase Price.<br>
                          <i>First Deposit + Second Deposit cannot be more than 10%.</i>`,
        depositAmount1: "The explicit amount for the first deposit.",
        depositRate2: `The second deposit, pay after deal's done, % of the Purchase Price. <br>
                          <i>First Deposit + Second Deposit cannot be more than 10%.</i>`,
        depositAmount2: "The explicit amount for the second deposit.",
        balancePaidWithinDays1:
          "The number of days which the <b>Second Deposit</b> shall be paid within.",
        balancePaidWithinDays2: `The number of days which the <b>Second Deposit</b> shall be paid within, 
                      from the date the Vendor obtained consent to transfer from the developer 
                      or approval from the relevant authorities.`,
        gracePeriod: "Addition grace period to the balance paid period.",
        interestRate:
          "Interest rate per annum on daily basis for balance not being paid on time.",
        spaExecutionDays1:
          "The number of working days which the SPA shall be executed within after buyer signed OTP.",
        spaExecutionDays2:
          "The number of working days which the vendor is allowed to execute the SPA, after the purchaser executed the SPA.",
        paymentAmount:
          "Explicit payment amount to the vendor attached to this OTP",
        otpValidDays:
          "The working day which vendor shall accept from the date hereof."
      },

      minValue
    };
  },
  computed: {},
  watch: {
    offerToPurchase: {
      handler(newVal) {
        this.$emit("change", newVal);
      },
      deep: true
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.offerToPurchase = this._.cloneDeep(this.otpData);
    }
  },
  methods: {
    getIsFieldRequired() {
      return this.required ? [required] : [];
    }
  }
};
</script>

<style lang="scss"></style>
